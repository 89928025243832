import { useMemo } from 'react';

import { TenantAffiliateResponseModel } from '@swagger-http';

import { CustomerSegment, InverterProviders, SurveyType } from '@store/enums';
import {
    useAppSelector,
    useEnergyProvider,
    useHasBattery,
    useHasGcp,
    useHasInverter,
    useHasWallbox,
    useHeatingDevices,
} from '@store/selectors';
import { getRegion } from '@tools/utils';

export const useSite = () => useAppSelector((state) => state.user.site);

export const useSiteId = () => useAppSelector((state) => state.user.site.id);

export const useSiteTimezone = () =>
    useAppSelector((state) => state.user.site.timezone) || 'Europe/London';

export const useUserSource = () => useAppSelector((state) => state.user.source);

export const useRegistrationDate = () => {
    const regDate = useAppSelector((state) => state.user.registrationDate);
    const joinDate = useAppSelector((state) => state.user.joinDate);

    const hasGCP = useHasGcp();
    const hasBattery = useHasBattery();
    const hasInverter = useHasInverter();

    return !hasGCP && !hasBattery && !hasInverter ? joinDate : regDate;
};

export const useHasSales = () => useAppSelector((state) => state.user.hasSales);

export const getCurrentTenantData = <T extends TenantAffiliateResponseModel>(
    tenants: T[],
    tenantId?: string,
): T | undefined =>
    tenants.find((tenant: T) =>
        tenantId
            ? tenant?.tenantId === tenantId
            : tenant?.country === getRegion(),
    );

export const getTenantItem = <
    T extends TenantAffiliateResponseModel,
    K extends keyof T,
>(
    tenant: T,
    tenants: T[],
    key: K,
    id?: string,
) => {
    if (!!tenant && Object.keys(tenant).length > 0) {
        return tenant[key];
    }

    const config = getCurrentTenantData(tenants, id);

    if (!config) {
        return;
    }

    return config[key];
};

export const useTenantData = <T extends keyof TenantAffiliateResponseModel>(
    field: T,
): TenantAffiliateResponseModel[T] => {
    const tenantId = useAppSelector((state) => state.user.tenantId);
    const { config, details } = useAppSelector(
        (state) => state.settings.tenants,
    );

    return getTenantItem(config, details, field, tenantId);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useReferralProgram = (isBanner: boolean = false): boolean => {
    // Sina said so 🤷‍♂️
    return false;

    // const joinDate = useAppSelector((state) => state.user.joinDate);
    // const appLoading = useAppLoading();
    // const hasInverter = useHasInverter();
    // const encryptedReference = useAppSelector(
    //     (state) => state.user.encryptedReference,
    // );
    // const referralProgramSeen = useAppSelector(
    //     (state) => state.settings.ui.referralProgramSeen,
    // );

    // if (!encryptedReference || !hasInverter || appLoading) {
    //     return false;
    // }

    // // The referral program is available for users which were created in the last 3 months
    // const start = Moment().subtract(3, 'months');
    // const bannerSeen = isBanner ? referralProgramSeen : false;
    // const isEligible = isBanner ? Moment(joinDate).isSameOrAfter(start) : true;

    // return isGermanUser() && isEligible && !bannerSeen;
};

// prettier-ignore
export const useCompletedCMSSurveys = () => useAppSelector((state) => state.user.surveys[SurveyType.CMS].completed);

// prettier-ignore
export const useLoginCount = (): number => useAppSelector((state) => state.user.loginCount);

export const useCustomerSegments = (): CustomerSegment[] => {
    const provider = useEnergyProvider();
    const hasWallbox = useHasWallbox();
    const heatingDevices = useHeatingDevices();

    const hasHeatingDevices = useMemo(
        () => heatingDevices.length > 0,
        [heatingDevices.length],
    );

    if (!provider && !hasWallbox && !hasHeatingDevices) {
        return [CustomerSegment.NO_DEVICES];
    }

    const result = [];

    if (provider) {
        result.push(
            provider === InverterProviders.UKMeter
                ? CustomerSegment.SMART_METER
                : CustomerSegment.SOLAR,
        );
    }

    if (hasWallbox) {
        result.push(CustomerSegment.EMOBILITY);
    }

    if (hasHeatingDevices) {
        result.push(CustomerSegment.HEATING_COOLING);
    }

    return result;
};

// prettier-ignore
export const useBillingRegion = () => useAppSelector(state => state.user.billingRegion)

// prettier-ignore
export const useBillingRegions = () => useAppSelector(state => state.user.billingRegions)

export const useShouldShowRegionSelector = (): boolean => {
    const { data } = useBillingRegions();

    if (data && data.length <= 1) {
        return false;
    }

    return true;
};
